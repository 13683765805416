import React from 'react';
import illustration from '../assets/images/section10-illustration.png';
import phone from '../assets/images/section10-phone.png';

class Section10 extends React.PureComponent {
    render(){
        return(
            <section id="section10" className='section-10-container'>
                <div className='display-flex-row section-10-body-container'>
                    <img src={phone} className='section10-mobile-img' alt=""/>
                    <div className='display-flex-column section10-text-container'>
                       <span className='font-dimbo-white section5-header-title'>Project Management</span>
                       <p className='section10-p font-bold-black'>
                            Buddy Spaces | Tasks | Milestones | File Sharing
                        </p>
                        <p className='section10-p'>
                            Create a Buddy space and start managing your team and projects easily and more effectively within a private
                            working space. No more lost mails or searching for comments and info on chat apps.
                        </p>
                        <p className='section10-p'>
                            Add Tasks &amp; Milestones, Chat to Individuals or Groups, set up Polls and Surveys &amp; centralise your File Sharing.
                        </p>
                        <div className='display-flex-row section10-illustration-container'>
                            <img src={illustration} className='section10-illustration'  alt=""/>
                            <div className='display-flex-column section10-ideas-container'>
                            <span className='font-dimbo-white section10-span-idea-text'>Ideas,</span>
                            <span className='font-dimbo-black section10-span-question-text'>question &amp; voting</span>
                            <p className='section10-p'>
                                    Encourage ideas and creative collective sharing.
                                    Create and share surveys, vote on a question or
                                    setup a polls.
                            </p>
                            </div>
                        </div>
                        
                   </div>
                  
               </div>
              
               
            </section>
        )
    }
}

export default Section10;