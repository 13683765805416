import React from 'react';
import ReactPlayer from 'react-player';

class Section4 extends React.PureComponent{
    render(){
        return (
            <section id='section-4' className='section-4-container player-wrapper'>
                <ReactPlayer 
                    className='react-player'
                    url='https://www.youtube.com/watch?v=ipjXGYCFBV8&t=3s' 
                    width='100%'
                    height='100%'
                    controls='true'
                />
            </section>
        )
    }
}

export default Section4;