import React from 'react';

class Section9 extends React.PureComponent {
    render(){
        return(
            <section id='section9' className='section-9-container'>
                <div className='display-flex-row section-9-body-container'>
                    <div className='blank-callout-bg section9-callout-container'>
                        <span className='font-dimbo-white text-bg-yellow section9-communicate-text'>communicate</span> 
                        <div className='display-flex-column callout-text-container'>
                            <span className='font-dimbo-yellow section9-callout-subtitle' >Chat | Group Chat | Messaging | Broadcast</span>
                            <span className='sm-font'>Easy &amp; convenient communication with a specific employee, group or department.</span>
                            <span className='sm-font'>Special Business announcements all integrated into a
                            user-friendly interface. (that people love to use daily,
                            without interfering in their private space.)</span>
                        </div>
                    </div>
                    <div className='display-flex-column section9-col2'>
                            <span className='section9-col2-first-span'>
                                Whether you need restaurant bookings, telephone numbers, business,
                                travel or home insurance, roadside assistance, emergency medical evacuation, travel arrangements, tyres for your car, skydiving lessons or a new
                                pair of shoe
                            </span>
                            <span className='font-playlist-white section9-col2-second-span'>whatever it may be</span>
                            <span className='font-bold-black section9-col2-third-span  sm-font'>....as your Buddy, I am here to help.</span>
                            <span className='font-dimbo-black section9-col2-fourth-span  sm-font'>@ your service with a Buddy smile</span>
                        </div>
                </div>
            </section>
        )
    }
}

export default Section9;