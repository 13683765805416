import React from 'react';
import section16img from '../assets/images/section15-parkingbuddy.png';

class Section16 extends React.PureComponent{
    render(){
        return(
            <section id='section-16' className='section-16-container'>
                <div className='display-flex-row section16-body-container'>
                    <div className='section16-text-container display-flex-column'>
                        <span className='section16-title text-bg-yellow font-dimbo-black'>Building &amp; Parking</span>
                        <span className='section16-title text-bg-white font-dimbo-black'>Access Management</span>
                        <p className='section16-p'>
                            Employees can use WorkBuddy to access parking garages, as well as buildings and offices with the Buddy Badge
                            build into the WorkBuddy app.
                        </p>
                        <p className='section16-p'>
                            The badge is a unique identifier and allows for secure access to parking garages, buildings as well as offices.
                        </p>
                        <p className='section16-p'>
                            Metrics &amp; stats as well as time and attendance can be linked to access and exit points
                        </p>
                    </div>
                    <img src={section16img} className='section16-parkbuddy' alt="" />
                </div>
            </section>
        )
    }
}

export default Section16;