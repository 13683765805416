import React from 'react';

class Home extends React.PureComponent {
    render () {
        return (
            <header className='masthead'>
                <img src={require('../assets/images/buddy-logo.png')} className="logo" alt="Buddy logo" />
                <img src={require('../assets/images/workbuddy-logo-black.png')} className="logo-word" alt="WorkBuddy logo" />
                <div className='download-container'>
                    <button className="btn btn-transparent">
                        <img src={require('../assets/images/switch-buddy.png')} alt="Switch To WorkBuddy in Buddy Super App"/>
                    </button>
                </div>
            </header>
        )
    }
}

export default Home;