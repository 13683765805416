import React from 'react';
import section6FG from '../assets/images/section7-fg.png';
import section6img from '../assets/images/girl-smile-whitebg-min.png';



class Section6 extends React.PureComponent {
    render () {
        return (
            <section id='section6' className='section-6-container'>
               <div className='display-flex-row section-6-body-container'>
                    <div className='display-flex-column section6-text-container'>
                        <span className='font-dimbo-white section6-header-title'>exciting</span>
                        <p className='section6-p'>  
                            The social engagement, daily scratch &amp; win promotion, loyalty points,
                            Smile$ Rewards &amp; virtual assistance are all very unique features within
                            WorkBuddy which contribute to the exciting experience of the app, driving
                            daily employee engagement
                        </p>
                        <span className='section6-read-more font-dimbo-black'>
                            read more...
                        </span>
                    </div>
                </div>
                <img src={section6FG} className='section6-foreground' alt=""/>
                <img src={section6img} className='background-girl' alt=""/>
            </section>
        )
    }
}

export default Section6;