import React from 'react';
import section8dude from '../assets/images/section8-dude.png';

class Section8 extends React.PureComponent {
    render () {
        return (
            <section id='section8' className='section-8-container'>
                <div className='display-flex-row section-8-body-container'>
                    <div className='display-flex-column section8-text-container'>
                        <div className='section8-text-right-container'>
                            <span className='font-dimbo-black section8-header-title'>centralized source of info</span>
                            <span className='font-dimbo-white section8-header-title'>&amp; documents</span>
                            <p className='section8-p-right'>     
                                Provide employees with a single space to quickly and conveniently
                                access all of their important company communication
                            </p>
                        </div>
                        <div className='section8-text-left-container'>
                            <span className='font-dimbo-white section8-header-title-left'>inform</span>
                            <p className='section8-subtitle section8-p-margin-left'>Timeline | News | Department | Fee</p>
                            <p className='section8-p-left section8-p-margin-left'>     
                                A social business news, info, event &amp; social feed with information categorised
                                for the organisation as a whole as well as department-specific and closed-group
                                specific content.
                            </p>
                            <p className='section8-p-left section8-p-margin-left'>
                                Categorise your content, add galleries, videos, presentations &amp; documents.
                            </p>
                        </div>
                    </div>
                    <img src={section8dude} className='section8-dude' alt=""/>
                </div>
            </section>
        )
    }
}

export default Section8;