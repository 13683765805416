import React from 'react';
import section14img from '../assets/images/section12-phone.png';

class Section14 extends React.PureComponent{
    render(){
        return(
            <section id='section-14' className='section-14-container'>
                <div className='display-flex-row section14-body-container'>
                    <div className='display-flex-column section14-text-container'>
                        <span className='font-dimbo-white section14-title'>SOS <span className='font-dimbo-yellow'>24/7</span></span>
                        <span className='text-bg-white font-dimbo-yellow section14-subtitle'>
                            A <span className='font-dimbo-black'>24/7 Panic &amp; SOS</span> alert trigger
                        </span>
                        <span className='section14-p'>
                            for all employees for Medical, Theft &amp; Fire Emergencies, pinpointing exact locations and sharing vital medical details with the suport &amp; response teams.
                        </span>
                        <span className='section14-p'>
                            Medical assistant on standby for questions regarding allergies &amp; symptom checks, providing expert health advice &amp; guidance.
                        </span>
                    </div>
                    <img src={section14img} className='section14-img' alt="" />
                </div>
            </section>
        )
    }
}

export default Section14;