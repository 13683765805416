import React from 'react';

const check = require('../assets/images/check.png');
const close = require('../assets/images/close.png');

class Comparison extends React.PureComponent {
    render () {
        return (
            <section id='comparison'>
                <div className='lg-comparison'>
                    <div className='checklist-block'>
                        <div className='th'>
                            LET'S COMPARE
                        </div>
                        <ul>
                            <li>Highly Flexible</li>
                            <li>Personal</li>
                            <li>Customizable</li>
                            <li>Engaging (Scan)</li>
                            <li>2 Way Communication</li>
                            <li>Rewarding (Win, Scan)</li>
                            <li>Chat</li>
                            <li>Virtual Assistant</li>
                            <li>Deals, Promo's, VIP</li>
                            <li>Loyalty Points</li>
                            <li>Scratch &amp; Win</li>
                            <li>Smile$ Rewards</li>
                            <li>SOS Panic</li>
                            <li>Buy Airtime &amp; Electricity</li>
                            <li>Marketplace</li>
                        </ul>
                    </div>
                    <div className='comparison-block'>
                        <div className='th'>
                            <span><span className='font-dimbo-black'>Work</span><span className='font-dimbo-yellow'>Buddy</span></span>
                        </div>
                        <ul>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt=""/>
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                        </ul>
                    </div>
                    <div className='comparison-block'>
                        <div className='th'>
                            <span style={{ height: 30 }}>OTHER COLLABORATION</span>
                            <span>SOFTWARE</span>
                        </div>
                        <ul>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={check} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                            <li>
                                <img src={close} alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
                {/* TABLE WHEN SMALLER DEVICE */}
                <div className='sm-comparison'>
                    <table className="tg">
                    <tr>
                        <th className="sm-lets-compare tg-c3ow">LET'S COMPARE</th>
                        <th className="cell-bg-white tg-c3ow"><span className='font-dimbo-black'>WORK</span><span className='font-dimbo-yellow'>BUDDY</span></th>
                        <th className="font-dimbo-black cell-bg-white  tg-c3ow">OTHER COLLABORATION SOFTWARE</th>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Highly Flexible</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt=""/></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt=""/></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Personal</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Customizable</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Engaging (Scan)</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; 2 Way Communication</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Rewarding (Win, Scan)</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Chat</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Virtual Assistant</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Deals, Promo's, VIP</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Loyalty Points</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Scratch &amp; Win</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Smile$ Rewards</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; SOS Panic</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    <tr>
                        <td className="tg-0pky sm-table-label"> &bull; Buy Airime &amp; Electricity</td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={check} alt="" /></td>
                        <td className="cell-bg-yellow tg-c3ow"><img className='sm-comparison-symbol' src={close} alt="" /></td>
                    </tr>
                    </table>
                </div>
            </section>
        )
    }
}

export default Comparison;