import React from 'react';
import workbuddycallout from '../assets/images/workbuddy-callout.png';
import arrowworkbuddyscreen from '../assets/images/arrow-hand-min.png';
import workbuddyscreen from '../assets/images/hand-mobile-min.png';


class Section3 extends React.PureComponent {
    render () {
        return (
            <section id='section3' className='section-3-container'>
               <div className='section3-upper-part display-flex-row'>
                   <img src={workbuddycallout} className='section-3-workbuddy-callout' alt=""/>
                   <div className='display-flex-column section-3-workbuddy-text-content'>
                        <span className='font-dimbo-black section-3-header-title'>WorkBuddy creates a </span>
                        <span className='font-dimbo-black text-bg-white section-3-header-title'>Business to employee collection</span>
                        <span className='font-dimbo-black section-3-header-title'>like no other.</span>
                        <p className='section3-p'>
                            The simple yet engaging personal, social &amp; business feed; virtual assistant; as well
                            as in-app feature-rich experiences excites, rewards, assists &amp; advises employees
                            daily, even outside the work environment
                        </p>
                        <p className='section3-p'>  
                            This stimulates an overall positive, creative, connected and driven work environment
                            as well as an all-round engaged work culture
                        </p>
                        <p className='section3-p'>
                            A sustained vision, a connected mission between work, employee and business with
                            a little assistance - meet WorkBudd
                        </p>
                   </div>
               </div>
               <div className='section3-lower-part display-flex-row'>
                   <div className='display-flex-column section-3-workbuddy-text-content section-3-workbuddy-lower-text-content'>
                        <span className='font-dimbo-yellow section-3-header-big-title'>personal </span>
                        <span className='font-dimbo-black section-3-header-title'>WorkBuddy creates a </span>
                        <span className='font-dimbo-white text-bg-black section-3-header-title'><span className='font-dimbo-yellow'>Business to employee</span> collection</span>
                        <span className='font-dimbo-black section-3-header-title'>like no other.</span>
                        <p className='section3-p'>
                        WorkBuddy creates a Business to employee connection like no other.
                        The simple yet engaging personal, social &amp; business feed; virtual assistant; as well as in-app feature-rich experiences excites, rewards, assists &amp; advises employees daily, even outside the work environment.
                        </p>
                        <p className='section3-p'>  
                        This stimulates an overall positive, creative, connected and driven work environment as well as an all-round engaged work culture.
                        A sustained vision, a connected mission between work, employee and business with a little assistance
                        </p>
                   </div>
                   <img src={arrowworkbuddyscreen} className='section-3-workbuddy-screen arrow-workbuddy-screen' alt=""/>
                   <img src={workbuddyscreen} className='section-3-workbuddy-screen hand-workbuddy-screen' alt=""/>
               </div>
            </section>
        )
    }
}

export default Section3;