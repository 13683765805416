import React from 'react';

class Section15 extends React.PureComponent{
    render(){
        return(
            <section id='section-15' className='section-15-container'>
                <div className='display-flex-row section15-body-container'>
                    <div className='display-flex-column section15-text-container'>
                        <span className='text-bg-black font-dimbo-white section15-title'>Marketplace</span>
                        <span className='section15-title font-dimbo-black section15-title-margin-left'><span className='section15-amp'>&amp;</span> <span className='font-dimbo-white'>Pinboard</span></span>
                        <p className='section15-p'>Employees can market and list personal items and services on the marketplace. A digital bulletin
                        board informs and connects people regarding social events, products, services and experiences
                        within the work environment. </p>
                    </div>
                </div>
            </section>
        )
    }
}

export default Section15;