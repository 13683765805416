import React from 'react';

class About extends React.PureComponent {
    render () {
        return (
            <section id='about'>
                <div className="about-content">
                    <img src={require('../assets/images/workbuddy-logo-yellow.png')} className="logo" alt=""/>
                    <span className='about-header-text'>Your modern-day Intranet &amp; Virtual Assistant</span>
                    <span><span className="font-bold-white">Here to help companies</span> <span className="font-bold-yellow">inform, connect, engage, excite, reward, share &amp;</span></span>
                    <span><span className="font-bold-yellow">assist employees</span> across all offices.</span>
                    <span>WorkBuddy brings your branches into one virtual office and allows your teams to</span>
                    <span>WorkBuddy brings your branches into one virtual office and allows your teams to</span>
                    <span>share real-time updates &amp; valuable company information</span>
                </div>
            </section>
        )
    }
}

export default About;