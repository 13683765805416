import React from 'react';
import section5mobile from '../assets/images/section-5-mobile-min.png';
import illustration from '../assets/images/section6-illustration-min.png';



class Section5 extends React.PureComponent {
    render () {
        return (
            <section id='section5' className='section-5-container'>
               <div className='display-flex-row section-5-body-container'>
                    <img src={section5mobile} className='section5-mobile-img' alt="" />
                    <div className='display-flex-column section5-text-container'>
                       <span className='font-dimbo-white section5-header-title'><span className='font-dimbo-black'>MULTI-WAY</span> COMMUNICATION</span>
                       <p className='section5-p'>
                            WorkBuddy allows a flow of information and engagement between management &amp; employee
                        </p>
                        <p className='section5-p'>
                            Standard intranets are commonly based on one-way communication
                        </p>
                        <p className='section5-p'>
                            A business shares a post on innovation on the intranet with the hope of this being communicated via email or messenger. Employees don’t typically react or read this and are soon to forget this was shared
                        </p>
                        <p className='section5-p'>
                            WorkBuddy allows a flow of information and engagement between management &amp; employees. 
                        </p>
                        <p className='section5-p'> 
                            A social business post is shared on the business feed. The information immediately reaches users via the social feed
                            which employees catch up on daily, during or after work via a standard business to employee post
                        </p>
                        <p className='section5-p'>                           
                            An important post can be shared as a pop-up and triggers a call to action
                        </p>
                        <p className='section5-p'>  
                        The word has spread quickly, employees start commenting and leaving positive feedback as well as praise. This in turn
                        creates more connections and future innovation as well as an all-round, feel-good, ‘Job well done’ experi
                        </p>
                        <img src={illustration} className='section5-illustration' alt=""/>
                   </div>
                   <span className='section5-bottom-text font-dimbo-black font-bold-black'>Easily share, quick to connect, engaging as well as rewarding on all ends without any real reward, just some simple serotonin release</span>
               </div>
               
            </section>
        )
    }
}

export default Section5;