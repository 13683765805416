import React from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Comparison from './pages/Comparison';
import Section3 from './pages/Section3';
import Section4 from './pages/Section4';
import Section5 from './pages/Section5';
import Section6 from './pages/Section6';
import Section7 from './pages/Section7';
import Section8 from './pages/Section8';
import Section9 from './pages/Section9';
import Section10 from './pages/Section10';
import Section11 from './pages/Section11';
import Section12 from './pages/Section12';
import Section13 from './pages/Section13';
import Section14 from './pages/Section14';
import Section15 from './pages/Section15';
import Section16 from './pages/Section16';

function App() {
	return (
		<div>
			<Home />
			<About />
			<Comparison />
			<Section3 />
			<Section4 />
			<Section5 />
			<Section6 />
			<Section7 />
			<Section8 />
			<Section9 />	
			<Section10 />
			<Section11 />
			<Section12 />
			<Section13 />
			<Section14 />
			<Section15 />
			<Section16 />			
		</div>
	);
}

export default App;
