import React from 'react';
import buddySmile from '../assets/images/section12-justask.png';

class Section13 extends React.PureComponent{
    render(){
        return(
            <section id='section-13' className='section-13-container'>
                <div className='display-flex-row section13-body-container'>
                    <img src={buddySmile} className='section13-img' alt="" />
                    <div className='display-flex-column section13-text-container'>
                        <span className='text-bg-white font-dimbo-black section13-title' > Buddy Virtual Assistant </span>
                        <span className='section13-p' > Your very own virtual assistant @ your service 24/7. </span>
                        <span className='section13-p' > Just ask Buddy anything and he will assist, inform, connect, book, insure, guide, source, negotiate. </span>
                        <span className='section13-p font-bold-black'> A personal mobile concierge service that gets you what you need when you need it. </span>
                        <span className='section13-p font-bold-black'> Your Personal Concierge Service for Local Trade Advice &amp; Assistance. </span>
                    </div>
                </div>
            </section>
        )
    }
}

export default Section13; 