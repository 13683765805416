import React from 'react';

class Section11 extends React.PureComponent{
    render(){
        return(
            <section id='section11' className='section-11-container'>
                <div className='display-flex-row section-11-body-container'>
                    <div className='section11-column-text-container'>
                        <span className='font-dimbo-yellow section11-title'>Smile$ Reward</span>
                        <span className='section11-p'>
                            
                            Reward employees on their performance
                            and engage them directly with Smile$
                            Rewards - a Business loyalty currency
                            that they can use to swap for products
                            and services as well as trade in the open
                            market for deals and specials.
                        </span>
                    </div>
                    
                </div>
            </section>
        )
    }
}

export default Section11;