import React from 'react';
import section12phones from '../assets/images/section11-phones.png';

class Section12 extends React.PureComponent{
    render(){
        return (
            <section className='section-12-container' id='section-12'>
                <div className='display-flex-row section-12-body-container'>
                <img src={section12phones} className='section12-mobile-img' alt="" />
                    <div className='display-flex-column section12-text-container'>
                        <span className='font-dimbo-white text-bg-yellow section12-title'>
                            Cloud Directory
                        </span>
                        <span className='section12-p'>
                            Work and Business Directory with contact numbers, e-mail, chat, designation, Short BIO at your fingertips.
                        </span>
                        <span className='font-dimbo-white text-bg-black section12-title'>
                            Content Targeting
                        </span>
                        <span className='section12-p'>
                            Create content and send communication to specific target groups. By using multiple channels you can share news, broadcast messages, create group chats,
                            &amp; Buddy Spaces projects linked to specific employees.
                        </span>
                    </div>
                </div>
            </section>
        )
    }
}

export default Section12;