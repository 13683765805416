import React from 'react';
import section7img from '../assets/images/curly-hair.png';



class Section7 extends React.PureComponent {
    render () {
        return (
            <section id='section7' className='section-7-container'>
               <div className='display-flex-row section-7-body-container'>
                    <img src={section7img} className='section7-background-girl' alt=""/>
                    <div className='display-flex-column section7-text-container'>
                        <span className='font-dimbo-black section7-header-title'>rewarding</span>
                        <p className='font-bold-black section7-subheader'>
                        Loyalty points, Smile$Rewards and virtual assistance
                        are driven by Buddy Industries</p>
                        <p className='section7-p'>  
                            Buddy Industries negotiates deals, sources products &amp; services and provides virtual
                            &amp; medical assistance and advice to employees as part of our professional business
                            relationship
                        </p>
                        <p className='section7-p'> 
                            This creates a sustained, engaging &amp; rewarding experience for employees without
                            putting strain on the business or any of its departments
                        </p>
                        <span className='section7-read-more font-dimbo-black'>
                            read more...
                        </span>
                    </div>
                </div>
            </section>
        )
    }
}

export default Section7;